<template>
            <a :href="`/aanbod/${getslug(car)}`" class="card d-flex flex-column text-decoration-none text-dark w-100 mt-4 h-100">
              <!-- <a class="card d-flex flex-column text-decoration-none text-dark w-100"> -->
                <div class="card-img-top aspect-ratio-4-3 crc-img-container cover br-tl-4 br-tr-4 position-relative">
                  <img :src="car?.photos?.[0]?.original">
                </div>
                <div class="px-3 px-md-4 px-lg-4 py-3 card-body d-flex flex-column">
                  <h3 :class="'fw-semibold crc-gray-text mb-1'">{{ car.mappeddata.brand }} {{car.mappeddata.model}}</h3>
                  <p v-if="car.mappeddata.type" class="mt-0 card-text flex-grow-1 crc-gray-text text-truncate fw-normal" v-html="car.mappeddata.type"></p>
                  <p v-else class="m-0 card-text flex-grow-1 crc-gray-text text-truncate invisible">TYPE</p>
                  <div class="flex-wrap mt-0 card-usps d-flex">
                    <span class="me-2 fs-8 px-1 py-0 bg-lightgrey rounded-1">{{ car.mappeddata.year }}</span>
                    <span class="me-2 fs-8 px-1 py-0 bg-lightgrey rounded-1">{{ car.mappeddata.milage }} km</span>
                    <span class="me-2 fs-8 px-1 py-0 bg-lightgrey rounded-1" v-if="!!car.extradata.transmissie">{{ (car.extradata.transmissie === 'A' || car.extradata.transmissie === 'Automaat') ? 'Automaat' : 'Handmatig' }}</span>
                    <span class="me-2 fs-8 px-1 py-0 bg-lightgrey rounded-1" v-else>{{ (car.mappeddata.transmission === 'A' || car.mappeddata.transmission === 'Automaat') ? 'Automaat' : 'Handmatig' }}</span>
                    <span class="me-2 fs-8 px-1 py-0 bg-lightgrey rounded-1">{{ translateDict[car.mappeddata.fuel] }}</span>
                  </div>
                  <div class="mt-4 card-price d-flex">
                    <div class="flex-1 mt-0 bochane-car-price">
                      <p class="mb-1 crc-gray-text fw-normal ">Prijs</p>
                      <p class="fw-semibold fs-4 crc-gray-text">
                        {{ this.carStore.settings.eurosign === 'no_euro' ? '' : '€' }}
                        {{ getFormattedPrice() }}
                      </p>

                    </div>
                    <div class="flex-1 mt-0 bochane-car-price" v-if="car.extradata.originele_prijs">
                      <p class="mb-1 crc-text-disabled">Originele prijs</p>
                      <p class="fw-semibold fs-5 crc-text-disabled text-decoration-line-through">{{ this.carStore.settings.eurosign === 'no_euro' ? '' : '€' }} {{'28.500'}}</p>
                    </div>
                  </div>
                  <div class="flex-wrap mt-1 card-dealerinfo d-flex flex-column text-nowrap">
                    <a role='button' v-if="car.location" target="_blank" :href='"https://maps.google.com/?q=" + car?.location?.address' class="flex-1 d-flex align-items-center text-grey me-3 text-decoration-none fs-7" style="max-width: 80%" @click.stop>
                    <svg class="my-1 me-2" width="16px" height="14px" style="min-width: 16px; white-space: pre-line;"><use xlink:href="#Icon--location"></use></svg>
                      <p class="my-1 crc-green-text crc-text-break fw-normal" v-if="car.location">{{ car.location?.address }} {{ car.location?.zipcode}} {{ car.location?.city}} </p>
                      <p class="my-1 crc-green-text crc-text-break fw-normal" v-else>{{ car.company.name}}</p>
                    </a>
                    <a role='button' v-if="car.location" :href='"tel:" + car?.location?.phone' class="flex-1 d-flex align-items-center text-grey text-decoration-none cursor-pointer fs-7" @click.stop>
                      <svg class="my-1 me-2" width="16px" height="14px"><use xlink:href="#Icon--phone"></use></svg>
                      <p class="my-1 crc-green-text fw-normal">{{ car.location?.phone ?? car?.company?.phone}}</p>
                    </a>
                  </div>
                </div>
            </a>
</template>

<script>
import { mapStores } from 'pinia'
import { useCarStore } from "@/stores/car";

export default {
  data() {
    return {
      carPrice: 0,
      translateDict: {
        "j":"Nieuw",
        "n":"Occasion",
        "B":"Benzine",
        "D":"Diesel",
        "B,E":"Hybride",
        "D,E":"Hybride",
        "E":"Elektrisch",
        "H":"Handgeschakeld",
        "A":"Automaat",
      },

    }
  },
  props: {
    car: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapStores(useCarStore),
  },
  methods: {
    domain() {
      return window.location.host;
    },
    getFormattedPrice() {
      const price = this.carStore.extractPrice(this.car);
      return this.formatNumber(price);
    },

    formatNumber(number) {
      return Number(number).toLocaleString('nl-NL').replace(/,/g, '.');
    },
    getslug(car) {
      return window.getslug(car);
    },
  },
};
</script>
