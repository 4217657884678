import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import mitt from 'mitt';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';

// Initialize Pinia Store
const pinia = createPinia();

// Initialize mitt emitter
const emitter = mitt();

// Create Vue App Instance
const app = createApp(App);

window.slugify = function (str) {
    return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
},

    window.getslug = function (car) {
        let type = car.mappeddata.type.replace(/[\W_]+/g, "-")
        let slug = car.mappeddata.slug.replace(/\D/g, "")
        return window.slugify(car.mappeddata.brand) + '-' + window.slugify(car.mappeddata.model) + '-' + window.slugify(type + '-') + window.slugify(slug);
    };

const mountEl = document.getElementById("crc-car-selection");

window.vsmApiUrl = mountEl.getAttribute('data-vsm-api') + "vue-endpoint/" + mountEl.getAttribute('data-vsm-apikey');
window.vsmApiKey = mountEl.getAttribute('data-vsm-ctc-apikey');
window.vsmVueUrl = mountEl.getAttribute('data-vsm-vue-url');

// Configure app instance
app.config.globalProperties.emitter = emitter;

// Use Pinia Store, Vue Router, VeeValidate, and Google Maps
app.use(pinia)

// Mount the app
app.mount('#crc-car-selection');
