<template>
<div ref="shadowHost" class="crc-car-component-container">
  <div ref="ctcAppContainer" class="appContainer">
  <HomeModule />

      <svg display="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
                <symbol id="Icon--phone" viewBox="0 0 15.83 15.206">
          <title>phone</title>
          <g transform="translate(0.764 0.74)">
            <path d="M1.545,2.094l.743-.743a.731.731,0,0,1,1.032.032l2,2A.731.731,0,0,1,5.357,4.42l-1.3,1.3L4.327,6.9a3.248,3.248,0,0,0,1.037,1.49l.989.989a3.246,3.246,0,0,0,1.489,1.036l1.18.271,1.3-1.3a.732.732,0,0,1,1.033.031l2,2a.732.732,0,0,1,.031,1.033l-.946.946-.354.354c-1.37,1.37-5.391-.432-6.848-1.888L2.876,9.5C1.419,8.043-.382,4.021.987,2.652Z" transform="translate(-0.5 -0.542)" fill="none" stroke="#556e6c" stroke-miterlimit="10" stroke-width="1.5"/>
            <path d="M15.425,7.262A8.48,8.48,0,0,0,8.516.493" transform="translate(-1.096 -0.493)" fill="none" stroke="#556e6c" stroke-miterlimit="10" stroke-width="1.5"/>
            <path d="M12.929,7.461A6.047,6.047,0,0,0,8.516,3.172" transform="translate(-1.096 -0.692)" fill="none" stroke="#556e6c" stroke-miterlimit="10" stroke-width="1.5"/>
          </g>
        </symbol>

        <symbol id="Icon--location" viewBox="0 0 12.17 15.339">
          <title>location</title>
          <g transform="translate(0.75 0.75)">
            <path d="M11.17,5.835a5.335,5.335,0,1,0-10.67,0c0,4.1,5.335,8.308,5.335,8.308s5.335-4,5.335-8.308" transform="translate(-0.5 -0.5)" fill="none" stroke="#556e6c" stroke-width="1.5"/>
            <path d="M11.17,5.835a5.335,5.335,0,1,0-10.67,0c0,4.1,5.335,8.308,5.335,8.308S11.17,10.144,11.17,5.835Z" transform="translate(-0.5 -0.5)" fill="none" stroke="#556e6c" stroke-miterlimit="10" stroke-width="1.5"/>
            <path d="M7.475,6.158A1.317,1.317,0,1,0,6.158,7.475,1.317,1.317,0,0,0,7.475,6.158" transform="translate(-0.823 -0.823)" fill="#556e6c"/>
          </g>
        </symbol>

      </defs>
      </svg>
  </div>
</div>
</template>

<script>
import HomeModule from './components/HomeModule.vue'

export default {
  name: 'App',
  components: {
    HomeModule
  },
  methods: {
    async createShadowDom() {
      const domain = window.vsmVueUrl ;
      const container = this.$refs.shadowHost

      let shadowRoot = container.shadowRoot || container.attachShadow({ mode: 'open' });

      const stylesheetMain = document.createElement('link');
      stylesheetMain.rel = 'stylesheet';
      stylesheetMain.href = `${domain}css/voorraad-app.css`;

      const stylesheetVendor = document.createElement('link');
      stylesheetVendor.rel = 'stylesheet';
      stylesheetVendor.href = `${domain}css/voorraad-app-vendor.css`;

      const CrcStyles = this.getOverrideStyles();
      const customCss = document.createElement("style");
      customCss.innerText = CrcStyles;

      shadowRoot.appendChild(stylesheetMain);
      shadowRoot.appendChild(stylesheetVendor);
      shadowRoot.appendChild(customCss);

      // Wrapping the load and error events in a Promise
      const loadStylesheet = new Promise((resolve, reject) => {
        stylesheetMain.onload = resolve;
        stylesheetMain.onerror = reject;
        stylesheetVendor.onload = resolve;
        stylesheetVendor.onerror = reject;
      });

      try {
        // Await for the stylesheet to be loaded
        await loadStylesheet;
        const content = this.$refs.ctcAppContainer;

        shadowRoot.appendChild(content);


      } catch (error) {
        console.error("Er is een fout opgetreden bij het laden van de shadowDom.", error);
      }
    },
    getOverrideStyles() {
       return`
        :host {
            --bs-blue: #0d6efd;
            --bs-indigo: #6610f2;
            --bs-purple: #6f42c1;
            --bs-pink: #d63384;
            --bs-red: #dc3545 !important;
            --bs-orange: #fd7e14;
            --bs-yellow: #ffc107;
            --bs-green: #198754;
            --bs-teal: #20c997;
            --bs-cyan: #0dcaf0;
            --bs-black: #000;
            --bs-white: #fff;
            --bs-gray: #6c757d;
            --bs-gray-dark: #343a40;
            --bs-gray-100: #f8f9fa;
            --bs-gray-200: #e9ecef;
            --bs-gray-300: #dee2e6;
            --bs-gray-400: #ced4da;
            --bs-gray-500: #adb5bd;
            --bs-gray-600: #6c757d;
            --bs-gray-700: #495057;
            --bs-gray-800: #343a40;
            --bs-gray-900: #212529;
            --bs-primary: #0d6efd;
            --bs-secondary: #6c757d;
            --bs-success: #198754;
            --bs-info: #0dcaf0;
            --bs-warning: #ffc107;
            --bs-danger: #dc3545;
            --bs-light: #f8f9fa;
            --bs-dark: #212529;
            --bs-primary-rgb: 13,110,253;
            --bs-secondary-rgb: 108,117,125;
            --bs-success-rgb: 25,135,84;
            --bs-info-rgb: 13,202,240;
            --bs-warning-rgb: 255,193,7;
            --bs-danger-rgb: 220,53,69;
            --bs-light-rgb: 248,249,250;
            --bs-dark-rgb: 33,37,41;
            --bs-primary-text-emphasis: #052c65;
            --bs-secondary-text-emphasis: #2b2f32;
            --bs-success-text-emphasis: #0a3622;
            --bs-info-text-emphasis: #055160;
            --bs-warning-text-emphasis: #664d03;
            --bs-danger-text-emphasis: #58151c;
            --bs-light-text-emphasis: #495057;
            --bs-dark-text-emphasis: #495057;
            --bs-primary-bg-subtle: #cfe2ff;
            --bs-secondary-bg-subtle: #e2e3e5;
            --bs-success-bg-subtle: #d1e7dd;
            --bs-info-bg-subtle: #cff4fc;
            --bs-warning-bg-subtle: #fff3cd;
            --bs-danger-bg-subtle: #f8d7da;
            --bs-light-bg-subtle: #fcfcfd;
            --bs-dark-bg-subtle: #ced4da;
            --bs-primary-border-subtle: #9ec5fe;
            --bs-secondary-border-subtle: #c4c8cb;
            --bs-success-border-subtle: #a3cfbb;
            --bs-info-border-subtle: #9eeaf9;
            --bs-warning-border-subtle: #ffe69c;
            --bs-danger-border-subtle: #f1aeb5;
            --bs-light-border-subtle: #e9ecef;
            --bs-dark-border-subtle: #adb5bd;
            --bs-white-rgb: 255,255,255;
            --bs-black-rgb: 0,0,0;
            --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
            --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
            --bs-body-font-family: var(--bs-font-sans-serif);
            --bs-body-font-size: 1rem;
            --bs-body-font-weight: 400;
            --bs-body-line-height: 1.5;
            --bs-body-color: #212529;
            --bs-body-color-rgb: 33,37,41;
            --bs-body-bg: #fff;
            --bs-body-bg-rgb: 255,255,255;
            --bs-emphasis-color: #000;
            --bs-emphasis-color-rgb: 0,0,0;
            --bs-secondary-color: rgba(33, 37, 41, 0.75);
            --bs-secondary-color-rgb: 33,37,41;
            --bs-secondary-bg: #e9ecef;
            --bs-secondary-bg-rgb: 233,236,239;
            --bs-tertiary-color: rgba(33, 37, 41, 0.5);
            --bs-tertiary-color-rgb: 33,37,41;
            --bs-tertiary-bg: #f8f9fa;
            --bs-tertiary-bg-rgb: 248,249,250;
            --bs-heading-color: inherit;
            --bs-link-color: #0d6efd;
            --bs-link-color-rgb: 13,110,253;
            --bs-link-decoration: underline;
            --bs-link-hover-color: #0a58ca;
            --bs-link-hover-color-rgb: 10,88,202;
            --bs-code-color: #d63384;
            --bs-highlight-color: #212529;
            --bs-highlight-bg: #fff3cd;
            --bs-border-width: 1px;
            --bs-border-style: solid;
            --bs-border-color: #dee2e6;
            --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
            --bs-border-radius: 0.375rem;
            --bs-border-radius-sm: 0.25rem;
            --bs-border-radius-lg: 0.5rem;
            --bs-border-radius-xl: 1rem;
            --bs-border-radius-xxl: 2rem;
            --bs-border-radius-2xl: var(--bs-border-radius-xxl);
            --bs-border-radius-pill: 50rem;
            --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
            --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
            --bs-focus-ring-width: 0.25rem;
            --bs-focus-ring-opacity: 0.25;
            --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
            --bs-form-valid-color: #198754;
            --bs-form-valid-border-color: #198754;
            --bs-form-invalid-color: #dc3545;
            --bs-form-invalid-border-color: #dc3545;
                /* Color */
            --vc-clr-primary: #000;
            --vc-clr-secondary: #090f207f;
            --vc-clr-white: #ffffff;
            /* Icon */
            --vc-icn-width: 1.2em;
            /* Navigation */
            --vc-nav-width: 30px;
            --vc-nav-height: 30px;
            --vc-nav-border-radius: 0;
            --vc-nav-color: var(--vc-clr-primary);
            --vc-nav-color-hover: var(--vc-clr-secondary);
            --vc-nav-background: transparent;
            /* Pagination */
            --vc-pgn-width: 12px;
            --vc-pgn-height: 4px;
            --vc-pgn-margin: 4px;
            --vc-pgn-border-radius: 0;
            --vc-pgn-background-color: var(--vc-clr-secondary);
            --vc-pgn-active-color: var(--vc-clr-primary);
            --bs-card-bg: var(--bs-card-bg, white);
            }
            .crc-car-component-container .card {
            --bs-card-spacer-y: 1rem;
            --bs-card-spacer-x: 1rem;
            --bs-card-title-spacer-y: 0.5rem;
            --bs-card-border-width: 1px;
            --bs-card-border-color: var(--bs-border-color-translucent);
            --bs-card-border-radius: 0.375rem;
            --bs-card-box-shadow: ;
            --bs-card-inner-border-radius: calc(0.375rem - 1px);
            --bs-card-cap-padding-y: 0.5rem;
            --bs-card-cap-padding-x: 1rem;
            --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
            --bs-card-cap-color: ;
            --bs-card-height: ;
            --bs-card-color: ;
            --bs-card-bg: #fff !important;
            --bs-card-img-overlay-padding: 1rem;
            --bs-card-group-margin: 0.75rem;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            height: var(--bs-card-height);
            word-wrap: break-word;
            background-color: var(--bs-card-bg);
            background-clip: border-box;
            border: var(--bs-card-border-width) solid var(--bs-card-border-color);
            border-radius: var(--bs-card-border-radius);
        }

  .crc-car-component-container .card>hr {
      margin-right: 0;
      margin-left: 0;
  }

  .crc-car-component-container .card>.list-group {
      border-top: inherit;
      border-bottom: inherit;
  }

  .crc-car-component-container .card>.list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius);
  }

  .crc-car-component-container .card>.list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius);
  }

  .crc-car-component-container .card>.card-header+.list-group,
  .crc-car-component-container .card>.list-group+.card-footer {
      border-top: 0;
  }

  .crc-car-component-container .card-body {
      flex: 1 1 auto;
      /* padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x); */
      color: var(--bs-card-color);
  }

  .crc-car-component-container .card-title {
      margin-bottom: var(--bs-card-title-spacer-y);
  }

  .crc-car-component-container .card-subtitle {
      margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
      margin-bottom: 0;
  }

  .crc-car-component-container .card-text:last-child {
      margin-bottom: 0;
  }

  .crc-car-component-container .card-link+.card-link {
      margin-left: var(--bs-card-spacer-x);
  }

  .crc-car-component-container .card-header {
      padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
      margin-bottom: 0;
      color: var(--bs-card-cap-color);
      background-color: var(--bs-card-cap-bg);
      border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  }

  .crc-car-component-container .card-header:first-child {
      border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  }

  .crc-car-component-container .card-footer {
      /* padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x); */
      color: var(--bs-card-cap-color);
      background-color: var(--bs-card-cap-bg);
      /* border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); */
  }

  .crc-car-component-container .card-footer:last-child {
      border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
  }

  .crc-car-component-container .card-header-tabs {
      margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
      margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
      margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
      border-bottom: 0;
  }

  .crc-car-component-container .card-header-tabs .nav-link.active {
      background-color: var(--bs-card-bg);
      border-bottom-color: var(--bs-card-bg);
  }

  .crc-car-component-container .card-header-pills {
      margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
      margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  }

  .crc-car-component-container .card-img-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: var(--bs-card-img-overlay-padding);
      border-radius: var(--bs-card-inner-border-radius);
  }

  .crc-car-component-container .card-img,
  .crc-car-component-container .card-img-top,
  .crc-car-component-container .card-img-bottom {
      width: 100%;
  }

  .crc-car-component-container .card-img,
  .crc-car-component-container .card-img-top {
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius);
  }

  .crc-car-component-container .card-img,
  .crc-car-component-container .card-img-bottom {
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius);
  }

  .crc-car-component-container .card-group>.card {
      margin-bottom: var(--bs-card-group-margin);
  }

    .bg-lightgrey {
      background-color: lightgrey;
    }

    .fs-7 {
      font-size: 13px;
    }

    .fs-8 {
      font-size: 12px;
    }

        #crc-btn-more {
            background-color: #E30613 !important;
            transition: background-color 0.3s ease-in-out;
        }
        #crc-btn-more:hover{
          background-color: #EB5059 !important;
        }

      .crc-text-break {
        white-space: pre-line;
      }

      * {
        font-family: "Montserrat", sans-serif;
      }

  `;
    },
  },
  mounted() {
    this.createShadowDom();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
