<template>
  <div class="container-fluid mt-3 mt-md-5 pt-5 pt-md-7 pb-5 md-mb-0 mb-6 crc-home-module">
    <div class="crc-car-selection-container d-flex flex-column">
      <div class="row gx-3">
        <div class="col-12">
          <h5 id="vsm-title" class="text-center text-red fw-bold mb-2">{{ title }}</h5>
          <div v-if="carStore.filteredCars.length === 0">
            <h3 class="fw-bold text-center mt-0">Er zijn geen auto's beschikbaar.</h3>
          </div>
          <div v-if="carStore.filteredCars.length > 0">
            <h2 id="vsm-subtitle" class="fw-bold text-center mt-0">{{ subtitle }}</h2>
            <div class="row crc-reffer-cards mt-3">
              <div id="vsm-car-column" class="col-sm-6 col-lg-4 mt-0 mt-sm-0 d-flex flex-column cursor-pointer" v-for="car in carStore.filteredCars" :key="car.id">
                <car-card :car="car" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a v-if="carStore.filteredCars.length > 0" id="crc-btn-more" href="/aanbod" class="mx-auto border-0 text-decoration-none">BEKIJK ALLE</a>
    </div>
  </div>
</template>

<script>
import { useCarStore } from "@/stores/car";
import CarCard from '@/components/CarCard.vue'

export default {
  name: 'HomeModule',
  components: {
    CarCard
  },
  data() {
    return {
      carStore: useCarStore(),
      title: '',
      subtitle: ''
    }
  },
  computed: {
    filteredCars() {

      return this.carStore.filteredCars;
    }
  },
  methods: {
    async fetchCars(forceUpdate = false) {
      await this.carStore.fetchCars(forceUpdate);

    },
  },
   mounted() {
    this.fetchCars();

    const element = document.getElementById('crc-car-selection');
    const title = element.getAttribute('data-vsm-title');
    const subtitle = element.getAttribute('data-vsm-subtitle');

    // Make the title and subtitle dynamic
    this.title = title ? title : '';
    this.subtitle = subtitle ? subtitle : '';

  },
}
</script>


