import { defineStore } from 'pinia';

export const useCarStore = defineStore({
    id: 'car',
    state: () => ({
        cars: [],
        filteredCars: [],
        settings: [],
        locations: [],
        car: {},
        loading: false,
        error: null
    }),
    getters: {
        async getCars() {
            if (!this.cars.length) {
                await this.fetchCars();
            }
            return this.cars;
        },
    },
    actions: {
        extractPrice(car) {
            let priceString = car.mappeddata.price;
            if (!isNaN(priceString)) {
                return parseFloat(priceString);
            }
            try {
                if (priceString.startsWith("'") && priceString.endsWith("'")) {
                    priceString = priceString.slice(1, -1);
                }
                let priceObject = JSON.parse(priceString);

                if (Array.isArray(priceObject.prijs)) {
                    return parseFloat(priceObject.prijs[0].bedrag);
                } else if (priceObject.prijs && priceObject.prijs.bedrag) {
                    return parseFloat(priceObject.prijs.bedrag);
                }
            } catch (error) {
                console.error("Error parsing price:", error);
            }
            return 0; // default price if parsing fails
        },
        sortCars(sortOrder) {
            const sortingStrategies = {
                'Price_low_high': (a, b) => this.extractPrice(a) - this.extractPrice(b),
                'Price_high_low': (a, b) => this.extractPrice(b) - this.extractPrice(a),
                'km_low_high': (a, b) => parseInt(a.mappeddata.milage) - parseInt(b.mappeddata.milage),
                'km_high_low': (a, b) => parseInt(b.mappeddata.milage) - parseInt(a.mappeddata.milage),
                'year_low_high': (a, b) => parseInt(a.mappeddata.year) - parseInt(b.mappeddata.year),
                'year_high_low': (a, b) => parseInt(b.mappeddata.year) - parseInt(a.mappeddata.year),
                'newest_first': (a, b) => new Date(b.created_at) - new Date(a.created_at),
                'oldest_first': (a, b) => new Date(a.created_at) - new Date(b.created_at),
            };

            if (sortingStrategies[sortOrder]) {
                this.filteredCars.sort(sortingStrategies[sortOrder]);
            }
        },
        applyFilters() {
            const translations = {
                'nieuw_voertuig': { 'N': 'Ocassion', 'D': 'Demo', 'J': 'Nieuw' },
                'brandstof': { 'B': 'benzine', 'B,E': 'benzine & elektrisch', 'D': 'diesel', 'E': 'elektrisch' },
            };

            const element = document.getElementById('crc-car-selection');
            if (!element) {
                console.error('Element with ID "crc-car-selection" not found');
                return;
            }

            const brands = element.getAttribute('data-vsm-merk')?.toLowerCase().split(',').filter(Boolean) || [];
            const models = element.getAttribute('data-vsm-model')?.toLowerCase().split(',').filter(Boolean) || [];
            const states = element.getAttribute('data-vsm-state')?.toLowerCase().split(',').filter(Boolean) || [];
            const buildData = element.getAttribute('data-vsm-build')?.trim();
            const buildRange = buildData.includes(',') ? buildData.split(',').map(Number) : [Number(buildData)];
            const branch = element.getAttribute('data-vsm-location');
            const keyWord = element.getAttribute('data-vsm-keyword')?.toLowerCase() || "";
            const fuelRegex = /"([^"]+)"|(\w+)/g;
            const fuels = [];
            let match;
            while ((match = fuelRegex.exec(element.getAttribute('data-vsm-fuel')?.toLowerCase())) !== null) {
                fuels.push(match[1] || match[2]);
            }

            this.filteredCars = this.cars.filter(car => {
                const carBrand = car.mappeddata.brand?.toLowerCase();
                const carModel = car.mappeddata.model?.toLowerCase();
                const carFuel = car.extradata.brandstof?.toLowerCase();
                const carState = car.extradata.nieuw_voertuig?.toLowerCase();
                const carBuildYear = Number(car.extradata.bouwjaar);
                const carBranch = car.location.id;
                const carKeyWord = car.extradata.opmerkingen?.toLowerCase() || "";
                let matchesBuild = false;
                if (buildRange.length === 1) {
                    matchesBuild = carBuildYear === buildRange[0];
                }
                else if (buildRange.length > 1) {
                    const minYear = Math.min(...buildRange);
                    const maxYear = Math.max(...buildRange);
                    matchesBuild = carBuildYear >= minYear && carBuildYear <= maxYear;
                }

                const matchesBrand = !brands.length || (carBrand && brands.includes(carBrand));
                const matchesModel = !models.length || (carModel && models.includes(carModel));
                const matchesBranch = !branch.length || (carBranch && branch.includes(carBranch));
                const matchKeyWord = carKeyWord && carKeyWord.includes(keyWord);
                const matchesFuel = !fuels.length || (carFuel && fuels.some(fuel => {
                    const translatedFuel = translations.brandstof[fuel.toUpperCase()]?.toLowerCase();
                    return carFuel === fuel || carFuel === translatedFuel || carFuel.includes(fuel);
                }));

                const matchesState = !states.length || (carState && states.includes(carState));

                return matchesBrand && matchesModel && matchesBuild && matchesBranch && matchesFuel && matchesState && matchKeyWord;
            });

            const sortOrder = element.getAttribute('data-vsm-sort');
            if (sortOrder) {
                this.sortCars(sortOrder);
            }

            const numCars = parseInt(element.getAttribute('data-vsm-num-cars'), 10);
            this.filteredCars = isNaN(numCars) ? this.filteredCars : this.filteredCars.slice(0, numCars);
        },


        async fetchCars(forceUpdate = false) {

            if (!forceUpdate && this.cars.length > 0) {
                return this.cars;
            }

            this.loading = true;
            try {
                const url = window.vsmApiUrl || 'https://crc.test/api/vue-endpoint/';

                const response = await fetch(url);
                const data = await response.json();
                this.filters = data.filter_options;
                this.cars = data.cars.filter(car => car.id !== 426 && car.photos.length > 0 && !!car.extradata.nieuw_voertuig);
                this.filteredCars = [...this.cars];
                this.mapped = data.mapped_fields;
                this.settings = data.settings;
                this.locations = data.locations;

                this.applyFilters();

                return this.cars;
            } catch (error) {
                this.error = error;
                console.error("Error fetching cars:", error);
            } finally {
                this.loading = false;
            }
        }
    }
});
